import React from 'react';
const API_URL = 'https://test.greenfield-club.ru/backend/api';
export default class Confirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
      login: false,
      auth_key: false,
    };
  }

  componentWillMount() {
    let urlParams = new URLSearchParams(this.props.location.search);
    let result = urlParams.get('result');
    let auth_key = urlParams.get('auth_key');
    if (parseInt(result) === 1 && auth_key !== null) {
      this.getProfile(auth_key);
    }
  }
  getProfile = async (auth_key) => {
    await fetch(API_URL + '/getProfile', {
      method: 'GET',
      headers: {
        'X-Auth-Token': auth_key,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response && response !== 'undefined' && response.result === true) {
          if (response.data.profile.userUuid !== '') {
            console.log(response);
            setTimeout(() => {
              localStorage.setItem('auth_key', auth_key);
              localStorage.setItem('auth_name', response.data.profile.name);
              localStorage.setItem('email', response.data.profile.email);
              localStorage.setItem('tel', response.data.profile.phone);
              localStorage.setItem('balance', response.data.balance);
              window.location.replace('/profile');
            }, 100);
          }
        } else {
          window.location.replace('/');
        }
      });
  };

  render() {
    return <div></div>;
  }
}
