import React from 'react';
import Section from './Section3';
const anchors = ['main', 'prizes', 'question-answer', 'winners'];

export default class Content extends React.Component {
  afterLoad(origin, destination, direction) {
    /*console.log("After load: " + destination.index);*/
  }
  render() {
    return (
      <div id="fullpage-wrap">
        <Section
          link={this.props.link}
          data={this.props.data}
          prizes={this.props.prizes}
          showLkModal={this.props.showLkModal}
          showFeedackModal={this.props.showFeedackModal}
          info5Show={this.props.info5Show}
          info7Show={this.props.info7Show}
          info8Show={this.props.info8Show}
          info4Show={this.props.info4Show}
          prizeShow={this.props.prizeShow}
          loginShow={this.props.loginShow}
        />
      </div>
    );
  }
}
