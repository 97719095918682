import React, { Component } from 'react';
import logo from '../assets/img/logo.svg';
import { Link, Events, animateScroll as scroll, scroller } from 'react-scroll';

require('es6-promise').polyfill();
require('isomorphic-fetch');
var url = document.createElement('a');
let urll = '';
let urllLength = '';
const API_URL = 'https://test.greenfield-club.ru/backend/api';
const widgetParams = {
  api: 'https://api.apmcheck.ru',
  apiKey: 'd9ff451f-dc0a-4480-aba6-d7faa0c6ea25',
  meta: { utm: 'utm_greenfield' },
  channel: 'channel_greenfield',
  userUuid: 'vladimir@devlify.com',
  maxPhotoSizeInMiB: 10,
  styles: {
    mainButton: 'custom-main-button',
    manualQrButton: 'custom-manual-qr-button',
    uploadPhotosButton: 'custom-upload-button',
    submitManualQrButton: 'custom-submit-manual-qr-button',
    addPhotoButton: 'custom-add-photo-button',
    submitPhotosButton: 'custom-submit-photos-button',
  },
  i18n: {
    labels: {
      mainButton: 'сканировать qr-код чека',
      manualQrButton: 'ввести данные вручную',
      uploadPhotosButton: 'загрузить фото чека',
      submitManualQrButton: 'отправить',
      addPhotoButton: 'загрузить фото чека',
      submitPhotosButton: 'отправить',
    },
    screens: {
      scanQr: {
        header: 'Загрузка чека<span>Сканирование</span>',
        subheader: 'Наведите камеру на&nbsp;QR-код',
      },
      manualInput: {
        header: 'Загрузка чека<span>ручной ввод данных чека</span>',
        subheader: '',
      },
      cameraNotAvailable: {
        header: 'Загрузка чека',
        subheader:
          'Нет доступа к камере устройства<br><br>Разрешите доступ к камере в настройка или введите данные вручную',
      },
      qrParsed: {
        subheader:
          'Чек принят в обработку. Сохраняйте оригинал чека до конца проведения программы лояльности.',
      },
      uploadFiles: {
        header: 'Загрузка чека<span>Загрузка фото</span>',
        subheader: 'Добавьте фото чека (до 5 частей)',
        fileTooLargeError: 'Файл больше 10 МБ. Загрузите файл меньшего размера.',
      },
      sentReceiptFailed: {
        header: 'Не удалось отправить чек!',
      },
    },
    errors: {
      '0002-0008': 'Ой, что-то пошло не так.',
    },
  },
  callbacks: {
    onReceiptSentSuccess: function (res) {
      window.dataLayer.push({
        event: 'gaEvent',
        eventCategory: 'internal',
        eventAction: 'do',
        eventLabel: 'check_success',
      });
      if (typeof window.__GetI === 'undefined') {
        window.__GetI = [];
      }
      (function () {
        var p = {
          type: 'CONVERSION',
          /* config START */
          site_id: '8835',
          order: [],
          transaction_id: '',
          revenue: '',
          pixel_id: 'Zagr_Chek',
          /* config END */
        };
        window.__GetI.push(p);
        var domain =
          typeof window.__GetI_domain == 'undefined' ? 'px.adhigh.net' : window.__GetI_domain;
        var src =
          ('https:' == document.location.protocol ? 'https://' : 'http://') + domain + '/t.js';
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = src;
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(script, s);
      })();

      setTimeout(() => {
        document.location.reload(true);
        window.pix_land('11822', '9608', '');
      }, 500);
    },
    onReceiptSentError: function (res) {},
  },
};
function getCode() {}
export default class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav: false,
      BodyClass: '',
      tab: 0,
      cabinet: false,
    };
  }
  componentDidMount() {
    setTimeout(() => {
      url.href = window.location.href;
      if (url.pathname === '/profile') {
        this.setState({
          cabinet: true,
        });
      } else {
        this.scrollTo(localStorage.getItem('page'));
      }
    }, 100);
    const script = document.createElement('script');

    script.src = 'https://apmcheck-widget.website.yandexcloud.net/js/widget.min.js';
    script.async = true;

    document.body.appendChild(script);
    if (
      localStorage.getItem('auth_key') &&
      localStorage.getItem('auth_key') !== '' &&
      localStorage.getItem('auth_key') !== null
    ) {
      this.getProfile();
    }
    this.getPrizeAll();
    // this.scrollTo();
  }
  pages = async (page) => {
    if (this.state.cabinet) {
      window.location.replace('/');
      localStorage.setItem('page', page);
    }
  };
  getPrizeAll = async () => {
    await fetch(API_URL + '/getPrizeAll2', {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        if (response !== null) {
          if (response.result === true || response === null) {
            this.props.data.setState({
              prizes: response.data,
            });
          } else {
            if (response.result !== null) {
              // console.log('Призов нет');
            }
          }
        }
      });
  };
  getProfile = async () => {
    await fetch(API_URL + '/getProfile', {
      method: 'GET',
      headers: {
        'x-auth-token': localStorage.getItem('auth_key'),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response && response !== 'undefined' && response.result === true) {
          if (response.data.profile.userUuid !== '') {
            widgetParams.userUuid = response.data.profile.userUuid;
            setTimeout(() => {
              window.qrWidget.init('apm-widget', widgetParams);
              if (response.data.profile.name) {
                localStorage.setItem('auth_name', response.data.profile.name);
                this.props.data.setState({
                  name: response.data.profile.name,
                });
              }
              if (response.data.profile.email) {
                localStorage.setItem('email', response.data.profile.email);
                this.props.data.setState({
                  email: response.data.profile.email,
                });
              }
              if (response.data.profile.phone) {
                localStorage.setItem('tel', response.data.profile.phone);
                this.props.data.setState({
                  phone: response.data.profile.phone,
                });
              }
              if (response.data.profile.balance) {
                localStorage.setItem('balance', response.data.profile.balance);
                this.props.data.setState({
                  balance: response.data.profile.balance,
                });
              }
              if (response.data.delivery.city) {
                localStorage.setItem('city', response.data.delivery.city);
                this.props.data.setState({
                  city: response.data.delivery.city,
                });
              }
              if (response.data.delivery.street) {
                localStorage.setItem('street', response.data.delivery.street);
                this.props.data.setState({
                  street: response.data.delivery.street,
                });
              }
              if (response.data.delivery.house) {
                localStorage.setItem('home', response.data.delivery.house);
                this.props.data.setState({
                  home: response.data.delivery.house,
                });
              }
              if (response.data.delivery.flat) {
                localStorage.setItem('flat', response.data.delivery.flat);
                this.props.data.setState({
                  flat: response.data.delivery.flat,
                });
              }
              if (response.data.delivery.postal_index) {
                localStorage.setItem('postalIndex', response.data.delivery.postal_index);
                this.props.data.setState({
                  postalIndex: response.data.delivery.postal_index,
                });
              }
            }, 100);
          }
        } else {
        }
      });
  };
  activeBodyClass = (tab) => {
    if (window.innerWidth < 1024) {
      this.setState({
        nav: !this.state.nav,
      });
    }
  };
  logout = async () => {
    window.location.replace('/');
    localStorage.removeItem('auth_key');
    localStorage.removeItem('auth_name');
    localStorage.removeItem('email');
    localStorage.removeItem('tel');
    localStorage.removeItem('scores');
    window.dataLayer.push({
      event: 'gaEvent',
      eventCategory: 'internal',
      eventAction: 'click',
      eventLabel: 'logout',
    });
  };
  goToContainer = async () => {
    scroller.scrollTo('scroll-container-element', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
      containerId: 'prize',
    });
  };
  scrollTo = async (pages) => {
    if (pages !== null) {
      scroller.scrollTo(pages, {
        duration: 2200,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    }
  };

  scrollToWithContainer = async () => {
    let goToContainer = new Promise((resolve, reject) => {
      Events.scrollEvent.register('end', () => {
        resolve();
        Events.scrollEvent.remove('end');
      });

      scroller.scrollTo('scroll-container', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    });

    goToContainer.then(() =>
      scroller.scrollTo('scroll-container-second-element', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
        containerId: 'scroll-container',
      }),
    );
  };
  renderHeaderRight = () => {
    if (url.pathname.split('/')[1] === 'profile') {
      return (
        <div className="header__right-logged-in">
          <a
            href="/"
            className="btn-lk btn-lk-prev"
            onClick={() => {
              window.dataLayer.push({
                event: 'gaEvent',
                eventCategory: 'internal',
                eventAction: 'click',
                eventLabel: 'back',
              });
            }}>
            Назад
          </a>
        </div>
      );
    } else {
      if (
        localStorage.getItem('auth_key') &&
        localStorage.getItem('auth_key') !== null &&
        localStorage.getItem('auth_key') !== undefined
      ) {
        return (
          <div className="header__right-logged-in">
            <a href="/profile" className="btn btn-lk">
              {this.props.data.state.name}
            </a>
            <div
              className="btn btn-logout"
              onClick={() => {
                this.logout();
              }}></div>
          </div>
        );
      } else {
        return (
          <button
            className="btn btn-lk"
            onClick={() => {
              this.props.loginShow();
              window.dataLayer.push({
                event: 'gaEvent',
                eventCategory: 'internal',
                eventAction: 'click',
                eventLabel: 'profile',
              });
            }}>
            Личный кабинет
          </button>
        );
      }
    }
  };
  renderBtnMain = () => {
    return (
      <li>
        <Link
          to="main"
          className="arrow__main"
          href="/#main"
          spy={true}
          smooth={true}
          duration={1500}
          onSetActive={this.handleSetActive}
          onClick={() => {
            this.pages('main');
            this.activeBodyClass();
          }}></Link>
      </li>
    );
  };
  renderBtnLogaut = () => {
    return (
      <a
        className="btn-logout"
        onClick={() => {
          this.logout();
        }}>
        <span></span>
      </a>
    );
  };
  renderBtnLkMobile = () => {
    if (localStorage.getItem('auth_key')) {
      return <a href="/profile" className="d-hidden header__btn-lc hamburger-box"></a>;
    } else {
      return (
        <div
          className="d-hidden header__btn-lc"
          onClick={() => {
            this.props.showLkModal();
          }}></div>
      );
    }
  };
  render() {
    return (
      <div className="header">
        <div className="container d-flex">
          <div className="logo__box d-flex align-items-center">
            <a href="/" className="logo">
              <img src={logo} className="nav-logo" alt="Logo" />
            </a>
          </div>
          <div className={'header__right ' + (this.state.nav === true ? 'active' : '')}>
            <ul id="menu" className="m-hidden">
              <li>
                <a
                  href={this.props.link.pravila + '.pdf'}
                  target="_BLANK"
                  onClick={() => {
                    window.dataLayer.push({
                      event: 'gaEvent',
                      eventCategory: 'internal',
                      eventAction: 'click',
                      eventLabel: 'rules',
                    });
                  }}>
                  Правила акции<span className="ico ico-loading"></span>
                </a>
              </li>
            </ul>
            {this.renderHeaderRight()}
          </div>
        </div>
      </div>
    );
  }
}
