import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home2 from './Home2';
import Cabinet from './Cabinet';
import Confirm from './Confirm';

export default class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      phone: '',
      address: '',
      balance: 0,
      city: '',
      street: '',
      home: '',
      flat: '',
      postalIndex: '',
      prizes: [],
    };
  }
  render() {
    const link = { pravila: 'rules', agreement: 'personal-data.pdf' };
    const prizes = [
      { id: 1, name: 'Шоппер', collBall: 150 },
      { id: 2, name: 'Чай в фирменной коробке', collBall: 300 },
      { id: 3, name: 'Плед', collBall: 300 },
      { id: 5, name: 'Свеча', collBall: 450 },
      { id: 9, name: 'Сборник поэзии', collBall: 500 },
      { id: 8, name: 'Толстовка', collBall: 600 },
    ];
    return (
      <Switch>
        <Route
          key="main"
          exact
          path="/"
          render={(props) => (
            <Home2 data={this} prizes={this.state.prizes} link={link} {...props} />
          )}
        />
        <Route
          path="/profile"
          render={(props) => (
            <Cabinet data={this} prizes={this.state.prizes} link={link} {...props} />
          )}
        />
        <Route path="/confirm" component={Confirm} />
        <Route path="/confirm" component={Confirm} data={this} />
        <Route path="*" component={Home2} />
      </Switch>
    );
  }
}
